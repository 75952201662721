import { BackgroundImage } from '../types';

export function requireFromStaticAsset(id: string): string {
  return require(`~static/assets/${id}`);
}

export function getAbsoluteAssetURI(uri: string): string {
  return /^https?:\/\//.test(uri) ? uri : requireFromStaticAsset(uri);
}

export function requireResourcesFromStaticAssets(resources: BackgroundImage): BackgroundImage {
  return Object.entries(resources).reduce(
    (acc, [key, resource]) => {
      acc[key] = requireFromStaticAsset(resource);
      return acc;
    },
    {} as BackgroundImage,
  );
}
