import React from 'react';
import { withRouter } from 'next/router';
import data from '../../../data/common.yml';
import withTrackPageView from '../../containers/with-track-page-view/WithTrackPageView';
import SiteLayout from '../site-layout/SiteLayout';
import Footer from '../../components/footer/Footer';
import AppNavigation from '../../templates/common/app-navigation/AppNavigation';
import { mapToNavigationItems } from '../../templates/common/mappers/mapToAppNavigationItems';
import mapToFooterItems from '../../utils/footer/mapToFooterItems';
import { AppNavigationVariant } from '../../templates/common/app-navigation/types';

export interface AppLayoutProps {
  activePage?: string,
  children: React.ReactNode,
  navigationVariant?: AppNavigationVariant,
}

const {
  leadPhone,
  callCenterWhatsApp,
  footer,
  appNavigation,
} = data;

const AppLayout: React.FC<AppLayoutProps> = ({
  navigationVariant,
  children,
  activePage,
}) => (
  <SiteLayout
    render={{
      header: (
        <AppNavigation
          whatsApp={callCenterWhatsApp}
          phone={leadPhone}
          items={mapToNavigationItems(appNavigation.items, activePage)}
          variant={navigationVariant}
        />
      ),
      footer: (
        <Footer
          whatsApp={callCenterWhatsApp}
          {...footer}
          links={mapToFooterItems(footer.links)}
        />
      ),
    }}
  >
    {children}
  </SiteLayout>
);

export default withRouter(withTrackPageView(AppLayout));
