import { BreadcrumbList, ListItem } from 'schema-dts';
import { BreadcrumbItem } from '../../client/components/breadcrumb/Breadcrumb';
import { SITE_BASE_URL } from '../../client/constants';
import { relativeToAbsoluteURL } from '../../client/utils/links';

export function getBreadcrumbStructuredData(items: BreadcrumbItem[]): BreadcrumbList {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Dove.it',
        item: SITE_BASE_URL,
      },
      ...items.map<ListItem>((item, i) => ({
        '@type': 'ListItem',
        position: i + 2,
        name: item.label,
        item: item.urlObject
          ? relativeToAbsoluteURL(item.urlObject.as)
          : undefined,
      })),
    ],
  };
}
