import {
  ViewNotifications,
} from '@doveit/lego';
import React from 'react';
import dynamic from 'next/dynamic';
import useNextConfig from '../../hooks/useNextConfig/useNextConfig';
import GlobalStyle from '../../themes/utils/global';
import ShameStyle from '../../themes/utils/shame';
import * as styles from './SiteLayout.style';

export interface SiteLayoutProps {
  render: {
    header?: React.ReactNode,
    footer?: React.ReactNode,
  },
  children: React.ReactNode,
}

const CookieConsent = dynamic(() => import('../../components/cookie-consent/CookieConsent'), {
  ssr: false,
});

const SiteLayout: React.FC<SiteLayoutProps> = ({
  children,
  render,
}) => {
  const { GTM } = useNextConfig();

  return (
    <div>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM.containerId}" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`,
        }}
      />
      <div css={styles.layout} $hasHeader={render.header}>
        {render.header && render.header}
        <div>
          {children}
        </div>
        {render.footer && render.footer}
        <div css={styles.notifications}>
          <div css={styles.notificationsBox}>
            <ViewNotifications />
          </div>
        </div>
      </div>
      <CookieConsent>
        Questo sito usa cookie, anche di terze parti, per migliorare l&apos;esperienza di navigazione. Navigando ne accetti l&apos;utilizzo. &nbsp;
        <a href="/cookie-policy" title="Più informazioni">
          Più informazioni
        </a>
      </CookieConsent>
      <GlobalStyle />
      <ShameStyle />
    </div>
  );
};

export default SiteLayout;
