import { css } from 'styled-components';
import {
  BreakpointQueryName, ThemedFunctor, rgba,
} from '@doveit/lego';
import variables from '../../../themes/variables';
import { AppNavigationVariant } from './types';

export const header: ThemedFunctor = ({
  theme,
  $variant,
}) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.navbar};
  background-color: ${$variant === AppNavigationVariant.LIGHT ? theme.palettes.neutrals.lightest : theme.palettes.brand.primary};
  color: ${$variant === AppNavigationVariant.LIGHT ? theme.palettes.brand.primary : theme.palettes.neutrals.lightest};

  ${$variant === AppNavigationVariant.LIGHT && `
    box-shadow: 0 0 10px 5px rgba(0,0,0, .05);
  `}
`;

export const actions = css`
  display: flex;
  align-items: center;
  line-height: 0;
  gap: .75rem;
`;

export const cta: ThemedFunctor = ({ theme }) => css`
  display: none;

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    display: block;
    margin-left: 1rem;
    flex-shrink: 0;
  }
`;

export const phone: ThemedFunctor = ({ theme }) => css`
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;

  > span {
      font-weight: normal;
      margin-left: .25rem;
      display: none;
    }

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    > span {
      display: block;
    }
  }
`;

export const mobileMenuWrapper: ThemedFunctor = ({
  theme,
  $mobileMenuOpen,
}) => css`
  background-color: ${theme.palettes.neutrals.lightest};
  border-top: 1px solid ${theme.palettes.neutrals.light};
  max-width: 17rem;
  position: fixed;
  top: ${variables.navigationHeight.mobile};
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: ${theme.zIndex.sidebar + 2};
  transition: transform .4s ease-in-out, visibility .4s ease-in-out;
  transform: translateX(0);
  will-change: transform;
  box-sizing: border-box;
  padding-top: ${theme.spaceUnits.mobile.SMALL};

  ${!$mobileMenuOpen && `
    visibility: hidden;
    transform: translateX(-100%);
  `}

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    visibility: hidden;
    transform: translateX(-100%);
  }
`;

export const mobileMenuOverlay: ThemedFunctor = ({
  theme,
  $mobileMenuOpen,
}) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  border: 0;
  outline: 0;
  appearance: none;
  display: block;
  z-index: ${theme.zIndex.sidebar + 1};
  background-color: ${rgba(theme.palettes.neutrals.darkest, 0.6)};
  cursor: pointer;
  transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
  will-change: opacity;

  ${!$mobileMenuOpen && `
    opacity: 0;
    visibility: hidden;
  `}

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    opacity: 0;
    visibility: hidden;
  }
`;
