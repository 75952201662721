import { css } from 'styled-components';
import {
  getTypography, ThemedFunctor, TypographyName, ellipsis, FontWeight,
} from '@doveit/lego';

export const base: ThemedFunctor = ({
  theme,
}) => css`
  background-color: ${theme.palettes.neutrals.lightest};
  padding: ${theme.spaceUnits.mobile.SMALLER} ${theme.spaceUnits.mobile.SMALL};
  cursor: pointer;
  display: block;
  width: 100%;
  line-height: 1;
`;

export const text: ThemedFunctor = ({
  theme,
  $active,
  $secondary,
  $highlighted,
}) => css`
  ${getTypography(theme, TypographyName.BODY)};
  ${ellipsis()}
  color: ${theme.palettes.brand.primary};
  font-weight: ${FontWeight.BOLD};
  text-transform: uppercase;

  ${$active && `
    text-decoration: underline;
  `}

  ${$secondary && `
    ${getTypography(theme, TypographyName.SMALL)};
    color: ${theme.palettes.neutrals.darkest};
    margin-left: 1rem;
    text-transform: none;
    font-weight: ${FontWeight.NORMAL};

    ${$highlighted && `
      margin-left: 0;
      color: ${theme.palettes.neutrals.lightest};
      background-color: ${theme.palettes.brand.secondary};
      padding: .5rem 1rem;
      border-radius: 6px;
    `}
  `}
`;
