import { JobOffer } from '../../../domain/graphcms';
import { withQueryString, buildURLObject } from './common';
import { JOB_OFFER_URL, JOB_OFFER_AS } from './paths';

export function buildJobOfferURLObject({
  id, slug, ...rest
}) {
  return buildURLObject(
    JOB_OFFER_URL,
    withQueryString(`${JOB_OFFER_AS}/${slug}/${id}`, rest),
    {
      id, slug, ...rest,
    },
  );
}

export function normalizeJobOfferProvince(jobOffer: JobOffer) {
  return jobOffer.province?.name ?? 'Tutta Italia';
}
