import React from 'react';
import {
  Symbol,
  Link,
  ICON_CLOSE,
  ICON_MENU,
} from '@doveit/lego';
import { URLObject } from '../../utils/links';
import * as styles from './MainNavigation.style';

export interface NavigationItem {
  id: string,
  urlObject: URLObject,
  label: string,
  onClick: VoidFunction,
  active?: boolean,
  subItems?: NavigationItem[],
}

export interface MainNavigationProps {
  render: {
    logo?: React.ReactElement,
    actions?: React.ReactElement,
  },
  items?: NavigationItem[],
  navigationControlHandler?: VoidFunction,
  mobileMenuOpen?: boolean,
}

const MainNavigation: React.FC<MainNavigationProps> = ({
  render = {},
  items = [],
  navigationControlHandler,
  mobileMenuOpen,
}) => {
  const [currentSubMenuOpen, setCurrentSubMenuOpen] = React.useState<string>();

  const onItemHoverHandler = React.useCallback(
    (id: string) => () => setCurrentSubMenuOpen(id), [],
  );

  const onItemLeaveHandler = () => setCurrentSubMenuOpen(undefined);

  return (
    <nav role="navigation">
      <div css={styles.wrapper}>
        <div css={styles.sideWrapper}>
          {(navigationControlHandler && typeof navigationControlHandler === 'function') && (
            <button
              css={styles.control}
              onClick={navigationControlHandler}
              data-ref="main-navigation-control"
              aria-label="Controllo navigazione"
            >
              <Symbol path={mobileMenuOpen ? ICON_CLOSE : ICON_MENU} />
            </button>
          )}
          <div css={styles.logo}>
            {render.logo}
          </div>
        </div>
        {(items && items.length > 0) && (
          <ul
            data-ref="main-navigation-items"
            css={styles.items}
          >
            {items.map((item) => (
              <li
                key={item.id}
                css={styles.item}
                data-ref="main-navigation-item"
                onMouseEnter={onItemHoverHandler(item.id)}
                onMouseLeave={onItemLeaveHandler}
                $active={item.active}
              >
                <Link
                  css={styles.link}
                  href={item.urlObject.as}
                  onClick={item.onClick}
                  data-ref="main-navigation-link"
                >
                  {item.label}
                </Link>
                {item.subItems && item.subItems.length > 0 && (
                  <div
                    css={styles.subMenu}
                    $open={currentSubMenuOpen === item.id}
                  >
                    <ul
                      css={styles.subMenuList}
                      $subItemsCount={item.subItems.length}
                    >
                      {item.subItems.map((subItem) => (
                        <li key={subItem.id}>
                          <Link
                            css={styles.subLink}
                            href={subItem.urlObject.as}
                            onClick={subItem.onClick}
                          >
                            {subItem.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        {render.actions && (
          <div>
            {render.actions}
          </div>
        )}
      </div>
    </nav>
  );
};

export default MainNavigation;
