import { RealEstateAgent } from 'schema-dts';

export function getAgencyStructuredData(
  locality: string,
  address: {
    locality: string,
    postalCode: string,
    streetAddress: string,
    region?: string,
    url: string,
  },
): RealEstateAgent {
  const {
    locality: addressLocality,
    postalCode,
    streetAddress,
    region,
    url,
  } = address;

  return {
    '@type': 'RealEstateAgent',
    name: `Dove.it agenzia immobiliare ${locality}`,
    image: 'https://cdn.dove.it/static/assets/logo.png',
    url,
    telephone: '+39 327 2074613',
    logo: {
      '@type': 'ImageObject',
      contentUrl: 'https://cdn.dove.it/static/assets/logo.png',
    },
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'Italia',
      addressLocality,
      addressRegion: region || undefined,
      streetAddress,
      postalCode,
    },
    slogan: `Vendi casa a Zero Commissioni con Dove.it ${locality}`,
    openingHours: ['Mo-Fr 09:30-19:30', 'Sa 09:30-13:30'],
  };
}
