import { withQueryString, buildURLObject, QueryObject } from './common';
import {
  NEWS_SERP_URL, NEWS_SERP_AS, NEWS_URL,
} from './paths';

export interface BuildNewsURLObject {
  id: string,
  slug: string,
  [key: string]: string,
}

export function buildNewsSerpURLObject(query: QueryObject = {}) {
  return buildURLObject(NEWS_SERP_URL, withQueryString(NEWS_SERP_AS, query), query);
}

export function buildNewsURLObject(
  { id, slug, ...rest }: BuildNewsURLObject,
) {
  return buildURLObject(
    NEWS_URL,
    withQueryString(`${NEWS_SERP_AS}/${slug}/${id}`, rest),
    { id, slug, ...rest },
  );
}
