import { css } from 'styled-components';
import { getSpacing, SpacingName, ThemedFunctor } from '@doveit/lego';

export const base: ThemedFunctor = ({
  $addCss,
}) => css`
  ${$addCss && $addCss}
`;

export const content: ThemedFunctor = ({
  theme,
  $maxWidth,
  $withoutPad,
}) => css`
  ${!$withoutPad && getSpacing.padding(theme, [0, SpacingName.SMALL])}
  margin: 0 auto;
  max-width: ${$maxWidth};
`;
