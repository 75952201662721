import { css } from 'styled-components';
import {
  grid, gridUnit, getSpacing, getTypography, SpacingName, TypographyName, BreakpointQueryName, ThemedFunctor,
} from '@doveit/lego';

export const base: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.paddingTop(theme, SpacingName.BIG)}
  padding-bottom: 9rem;
  background-color: ${theme.palettes.neutrals.darkest};

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    padding-bottom: ${theme.spaceUnits.desktop.BIG};
  }
`;

export const inner: ThemedFunctor = ({ theme }) => css`
  ${grid(theme)}
  ${getSpacing.paddingTop(theme, SpacingName.BIG)}
  padding-bottom: 9rem;
  color: ${theme.palettes.neutrals.lightest};
`;

export const infoCell: ThemedFunctor = ({ theme }) => css`
  ${gridUnit(theme, { size: { LG: 1 / 2 } })}
`;

export const logo = css`
  width: 10rem;
`;

export const tagline: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.H3)}
  ${getSpacing.marginTop(theme, SpacingName.SMALL)}
  display: block;
  text-align: left;
`;

export const info: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    ${getSpacing.marginTop(theme, SpacingName.SMALL)}
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    text-align: left;
  }

  a {
    text-decoration: none;
    color: ${theme.palettes.neutrals.lightest};
  }

  a:hover {
    text-decoration: underline;
  }
`;

export const socialIcons: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.margin(theme, [SpacingName.MEDIUM, 0, SpacingName.SMALLER, 0])}

  & > a {
    ${getSpacing.marginRight(theme, SpacingName.SMALL)}
  }

  & > a:last-child {
    margin-right: 0;
  }
`;

export const nav: ThemedFunctor = ({ theme }) => css`
  ${grid(theme)}
  ${gridUnit(theme, { size: { LG: 1 / 2 } })}

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    margin-top: ${theme.spaceUnits.desktop.MEDIUM};
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    margin-top: 0;
  }
`;

export const linksCell: ThemedFunctor = ({ theme }) => css`
  ${gridUnit(theme, { size: { MD: 1 / 2 } })}
  margin-top: ${theme.spaceUnits.desktop.BIG};

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    margin-top: 0;
    padding-left: ${theme.spaceUnits.desktop.MEDIUM};
    margin-bottom: ${theme.spaceUnits.desktop.SMALL};
  }
`;

export const linksGroupTitle: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY)}
  font-weight: bold;
  text-transform: uppercase;
`;

export const link: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY)}

  a {
    display: block;
    padding: 0.5rem 0;
    text-decoration: none;
    color: ${theme.palettes.neutrals.lightest};
  }

  a:hover {
    text-decoration: underline;
  }
`;
