import { ButtonProps } from '@doveit/lego';
import { QuizEntry, QuizQuestionCategory } from '../domain/graphcms';
import { URLObject } from './utils/links';

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum WrapperSize {
  FULL = '100%',
  S = '30rem',
  SM = '40rem',
  M = '50rem',
  L = '75rem',
  XL = '85.375rem',
}

export type FormFieldConfig = {
  label?: string,
  text?: string,
  ariaLabel?: string,
  placeholder?: string,
  required?: boolean,
  hidden?: boolean,
  disabled?: boolean,
  fields?: FormFieldsConfig,
};

export type FormFieldsConfig = { [key: string]: FormFieldConfig };

export interface NavigationItem {
  id: string,
  label: string,
  url: string,
  as: string,
  mobileOnly: boolean,
  highlighted?: boolean,
  subItems: NavigationItem[],
}

export type CTA = Pick<ButtonProps, 'label' | 'aria-label' | 'href' | 'dataRef' | 'dataTracking'>;

export interface City {
  title: string,
  picture: string,
  smallPicture: string,
  locality: string,
  averageSellPrice: number,
  urlObject?: URLObject,
  highlighted?: boolean,
}

export interface BackgroundImage {
  SMALL?: string,
  LARGE: string,
}

export interface HeroSection {
  title: string,
  description?: string,
  cta?: CTA,
  backgroundImage?: BackgroundImage,
  formTitle?: string,
}

export interface Feature {
  id: string,
  title: string,
  picture: string,
  description: string,
  cta?: {
    label: string,
    highlight: boolean,
    urlObject: URLObject,
  },
}

export interface Video {
  title: string,
  description: string,
  thumbnail: string,
  uploadDate: string,
  duration: string,
  contentUrl: string,
  embedUrl: string,
}

export interface CategoryItem {
  id: string,
  label: string,
  urlObject: URLObject,
}

export type QuizEntries = {
  [key in QuizQuestionCategory]: QuizEntry[]
};
