import { SITE_BASE_URL } from '../../client/constants';

const base = {
  '@context': 'http://schema.org/',
  '@graph': [
    {
      '@type': 'WebSite',
      name: 'Dove.it',
      url: SITE_BASE_URL,
    },
  ],
};

export function getStructuredData(...args) {
  return {
    ...base,
    '@graph': [
      ...base['@graph'],
      ...args.filter((arg) => !!arg),
    ],
  };
}

export * from './organization';
export * from './agency';
export * from './breadcrumb';
export * from './property';
export * from './blogArticle';
