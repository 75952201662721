import { NavigationItem } from '../../../types';
import { goToPage } from '../../../utils/links';
import { AppNavigationItem } from '../app-navigation/AppNavigation';

export function mapToNavigationItems(items: NavigationItem[], activeItem?: string): AppNavigationItem[] {
  return items.map((item) => {
    const normalizedItem = {
      id: item.id,
      label: item.label,
      urlObject: {
        as: item.as,
        url: item.url,
      },
      mobileOnly: item.mobileOnly,
      active: activeItem === item.id,
      highlighted: item.highlighted,
      onClick: () => goToPage(item),
      subItems: null,
    };

    if (item.subItems) {
      normalizedItem.subItems = mapToNavigationItems(item.subItems, activeItem);
    }

    return normalizedItem;
  });
}
