import React from 'react';
import { LinkOnClickEvent } from '@doveit/lego';
import SideNavigationItem, { SideNavigationItemProps } from './parts/side-navigation-item/SideNavigationItem';

interface Item {
  id: string | number,
}

export interface SideNavigationProps {
  activeItemId?: Item['id'],
  onCommonClick?: (item: Item) => void,
  onSelectedItemChange?: (item: Item) => void,
}

export interface SideNavigationComposition {
  Item: typeof SideNavigationItemWithContext,
  SubItem: typeof SideNavigationSubItem,
}

export interface SideNavigationItemWithContextProps extends SideNavigationItemProps {
  id: Item['id'],
}

export interface SideNavigationContextValue {
  activeItemId?: Item['id'],
  onItemClick?: (item: Item & {
    customAction?: (event: LinkOnClickEvent) => void,
  }) => (event: LinkOnClickEvent) => void,
}

export const SideNavigationContext = React.createContext<SideNavigationContextValue>({});

const SideNavigationItemWithContext: React.FC<SideNavigationItemWithContextProps> = ({
  id,
  onClick,
  secondary = false,
  highlighted = false,
  ...rest
}) => (
  <SideNavigationContext.Consumer>
    {({
      activeItemId,
      onItemClick,
    }) => (
      secondary
        ? (
          <SideNavigationItem
            {...rest}
            onClick={onItemClick({ id, customAction: onClick })}
            active={activeItemId === id}
            secondary
            highlighted={highlighted}
          />
        )
        : (
          <li>
            <SideNavigationItem
              {...rest}
              onClick={onItemClick({ id, customAction: onClick })}
              active={activeItemId === id}
              highlighted={highlighted}
            />
          </li>
        )
    )}
  </SideNavigationContext.Consumer>
);

const SideNavigationSubItem: React.FC<SideNavigationItemWithContextProps> = (props) => (
  <SideNavigationItemWithContext secondary {...props} />
);

const SideNavigation: React.FC<SideNavigationProps> & SideNavigationComposition = ({
  activeItemId,
  onCommonClick,
  onSelectedItemChange,
  children,
}) => {
  const onItemClick: SideNavigationContextValue['onItemClick'] = React.useCallback(({ id, customAction }) => (event) => {
    if (onSelectedItemChange) {
      onSelectedItemChange({ id });
    }

    if (customAction) {
      customAction(event);
      return;
    }

    if (onCommonClick) {
      onCommonClick({ id });
    }
  }, [onCommonClick, onSelectedItemChange]);

  const contextValue: SideNavigationContextValue = React.useMemo(() => ({
    activeItemId,
    onItemClick,
  }), [activeItemId, onItemClick]);

  return (
    <SideNavigationContext.Provider value={contextValue}>
      <nav role="navigation">
        <ul>
          {children}
        </ul>
      </nav>
    </SideNavigationContext.Provider>
  );
};

SideNavigation.Item = SideNavigationItemWithContext;
SideNavigation.SubItem = SideNavigationSubItem;

export default SideNavigation;
