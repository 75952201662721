import { buildURLObject, withQueryString } from './common';
import { AGENT_AS, AGENT_URL } from './paths';

export interface BuildAgentURLObject {
  slug: string,
  [key: string]: string,
}

export function buildAgentURLObject({ slug, ...rest }: BuildAgentURLObject) {
  return buildURLObject(AGENT_URL, withQueryString(`${AGENT_AS}/${slug}`, rest), { slug, ...rest });
}
