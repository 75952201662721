import React, { FunctionComponent } from 'react';
import { Symbol } from '@doveit/lego';
import {
  FACEBOOK as FACEBOOK_ICON,
  INSTAGRAM as INSTAGRAM_ICON,
  LINKEDIN as LINKEDIN_ICON,
  TWITTER as TWITTER_ICON,
  YOUTUBE as YOUTUBE_ICON,
} from '../../themes/icons';
import * as styles from './SocialIcon.style';

export enum Social {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export type SocialIconType = {
  title: string,
  path: string,
  href: string,
};

export interface SocialIconProps {
  type: SocialIconType,
  color?: string,
}

export const SocialIconConfig: { [key in Social]: SocialIconType } = {
  FACEBOOK: {
    title: 'Pagina Facebook di dove.it',
    path: FACEBOOK_ICON,
    href: 'https://www.facebook.com/doveitofficial',
  },
  LINKEDIN: {
    title: 'Pagina LinkedIn di dove.it',
    path: LINKEDIN_ICON,
    href: 'https://www.linkedin.com/company/doveit/',
  },
  INSTAGRAM: {
    title: 'Pagina Instagram di dove.it',
    path: INSTAGRAM_ICON,
    href: 'https://www.instagram.com/dove.it_official',
  },
  TWITTER: {
    title: 'Pagina Twitter di dove.it',
    path: TWITTER_ICON,
    href: 'https://twitter.com/Doveit_official',
  },
  YOUTUBE: {
    title: 'Canale YouTube di dove.it',
    path: YOUTUBE_ICON,
    href: 'https://www.youtube.com/channel/UC9QqNsor6oOT0UyY35uSU0g',
  },
};

const SocialIcon: FunctionComponent<SocialIconProps> = ({
  type,
  color = 'neutrals.lightest',
}) => {
  const { href, title, path } = type;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      target="_blank"
      rel="noopener nofollow"
      $color={color}
      css={styles.icon}
      title={title}
    >
      <Symbol
        path={path}
      />
    </a>
  );
};

export default SocialIcon;
