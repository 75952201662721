import { snakeCase as snakeCaseFn } from 'snake-case';

function isArray(arr: any) {
  return Array.isArray(arr);
}

function isObject(obj: any) {
  return obj === Object(obj);
}

function isString(str: any) {
  return typeof str === 'string';
}

export function camelCase(str: string) {
  if (!str) {
    return str;
  }

  return str
    .split(' ')
    .map((token) => `${token[0].toUpperCase()}${token.substring(1).toLowerCase()}`).join(' ');
}

export function snakeCase(str: string) {
  return snakeCaseFn(str);
}

export function template(any: any, replace: { [key: string]: any }) {
  const REGEX = /\{\w+\}/g;
  const BOUNDARIES_REGEX = /\{|\}/g;

  if (isString(any)) {
    return any.replace(REGEX, (match) => replace[match.replace(BOUNDARIES_REGEX, '')]);
  }

  if (isObject(any) && !isArray(any)) {
    return Object.keys(any).reduce((accumulator, key) => {
      const value = any[key];
      accumulator[key] = template(value, replace);

      return accumulator;
    }, {});
  }

  if (isArray(any)) {
    return any.map((value) => template(value, replace));
  }

  return any;
}

export function stripMarkdown(str: string): string {
  // Remove horizontal rules
  return str.replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*$/gm, '')
    .replace(/^([\s\t]*)([*\-+]|\d+\.)\s+(\[(\s|x|X)\]\s+)?/gm, '$1')
    // Remove images
    .replace(/!\[(.*?)\][[(].*?[\])]/g, '')
    // Remove inline links
    .replace(/\[(.*?)\][[(].*?[\])]/g, '$1')
    // Remove blockquotes
    .replace(/^\s{0,3}>\s?/g, '')
    // Remove headers
    .replace(/^(\n)?\s{0,}#{1,6}\s+| {0,}(\n)?\s{0,}#{0,} {0,}(\n)?\s{0,}$/gm, '$1$2$3')
    // Remove emphasis (repeat the line to remove double emphasis)
    .replace(/([*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
    .replace(/([*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
    // Remove strikethrough
    .replace(/^(~{2})(\S.*?\S{0,1})\1/g, '$2')
    // Remove table rows (do it before cells)
    .replace(/(\|-+)+\|$\n/gm, '')
    // Remove table cell delimiters
    .replace(/((^| +)\|( +|$))/gm, ' ')
    .replace(/^ /gm, '')
    .replace(/ $/gm, '')
    // Remove code blocks
    .replace(/(`{3,})(.*?)\1/gm, '$2')
    // Remove inline code
    .replace(/`(.+?)`/g, '$1');
}
