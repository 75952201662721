import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Link, LinkOnClickEvent } from '@doveit/lego';
import * as styles from './SideNavigationItem.style';
import { URLObject } from '../../../../utils/links';

export interface SimpleNavigationItemProps {
  label?: string,
  onClick?: (event: LinkOnClickEvent) => void,
  active?: boolean,
  highlighted?: boolean,
  title?: string,
  ariaLabel?: string,
  urlObject?: URLObject,
  secondary?: boolean,
}

export type SideNavigationItemProps = PropsWithChildren<SimpleNavigationItemProps>;

export const SimpleNavigationItem: FunctionComponent<SimpleNavigationItemProps> = ({
  label,
  onClick,
  active,
  ariaLabel,
  title,
  urlObject,
  secondary,
  highlighted,
}) => (
  <Link
    css={styles.base}
    onClick={onClick}
    aria-label={ariaLabel || label}
    title={title || label}
    href={urlObject?.as}
  >
    <span
      css={styles.text}
      $active={active}
      $highlighted={highlighted}
      $secondary={secondary}
    >
      {label}
    </span>
  </Link>
);

export const SideNavigationItem: FunctionComponent<SideNavigationItemProps> = ({
  children,
  ...rest
}) => (
  <>
    <SimpleNavigationItem {...rest} />
    {children && (
      <div>
        {children}
      </div>
    )}
  </>
);

export default SideNavigationItem;
