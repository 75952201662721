import { FooterLinkGroup } from '../../components/footer/Footer';
import { goToPage } from '../links';

export interface FooterLinkGroupData {
  groupName: string,
  items: {
    label: string,
    url: string,
    as: string,
    title: string,
  }[],
}

export default function mapToFooterItems(linkGroups: FooterLinkGroupData[]): FooterLinkGroup[] {
  return linkGroups.map((group) => {
    const normalizedGroup = {
      groupName: group.groupName,
      items: group.items.map((item) => {
        const normalizedItem = ({
          label: item.label,
          urlObject: {
            url: item.url,
            as: item.as,
          },
          onClick: () => goToPage(item),
          title: item.title,
        });

        return normalizedItem;
      }),
    };
    return normalizedGroup;
  });
}
