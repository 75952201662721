import React, { Component, ComponentType } from 'react';
import { NextRouter } from 'next/router';
import { trackPageView } from '../../services/tracking/tracking';

export interface WithTrackPageViewProps {
  router: NextRouter,
}

export default function withTrackPageView<P>(WrappedComponent: ComponentType<P>) {
  class WithTrackPageView extends Component<P & WithTrackPageViewProps> {
    componentDidMount() {
      const { router } = this.props;
      router.events.on('routeChangeComplete', this.track);
    }

    componentWillUnmount() {
      const { router } = this.props;
      router.events.off('routeChangeComplete', this.track);
    }

    track = () => {
      const { router } = this.props;

      setTimeout(() => {
        trackPageView({
          pathname: router.asPath ? router.asPath : router.pathname,
          title: window.document.title,
        });
      }, 0);
    };

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }

  return WithTrackPageView;
}
