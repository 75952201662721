import {
  ThemedFunctor, BreakpointQueryName, getSpacing, SpacingName,
} from '@doveit/lego';
import { css } from 'styled-components';
import variables from '../../themes/variables';

export const layout: ThemedFunctor = ({ theme, $hasHeader }) => css`
  ${$hasHeader && `
    padding-top: ${variables.navigationHeight.mobile};

    ${theme.breakpoints.up(BreakpointQueryName.XL)} {
      padding-top: ${variables.navigationHeight.desktop};
    }
  `}
`;

export const notifications: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, [0, SpacingName.SMALL])}
  z-index: ${theme.zIndex.overthetop};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

export const notificationsBox: ThemedFunctor = () => css`
  pointer-events: all;
  max-width: 50rem;
  margin: 0 auto;
`;
