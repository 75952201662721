import React, { FunctionComponent, useState, useCallback } from 'react';
import Link from 'next/link';
import {
  BreakpointQueryName, Button, ButtonSize, ICON_PHONE, ICON_WHATSAPP, Logo, Symbol,
} from '@doveit/lego';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import LayoutWrapper from '../../../layouts/layout-wrapper/LayoutWrapper';
import SideNavigation from '../../../components/side-navigation/SideNavigation';
import MainNavigation from '../../../components/main-navigation/MainNavigation';
import { buildEvaluationURLObject, goToPage, URLObject } from '../../../utils/links';
import * as styles from './AppNavigation.style';
import { WrapperSize } from '../../../types';
import ChatOnWhatsApp from '../../../containers/chat-on-whatsapp/ChatOnWhatsApp';
import { AppNavigationVariant } from './types';

export interface AppNavigationItem {
  id: string,
  urlObject: URLObject,
  label: string,
  onClick: VoidFunction,
  active?: boolean,
  highlighted?: boolean,
  mobileOnly?: boolean,
  subItems?: AppNavigationItem[],
}

export interface AppNavigationProps {
  whatsApp: string | number,
  phone: string,
  items?: AppNavigationItem[],
  hideActions?: boolean,
  variant?: AppNavigationVariant,
}

const evaluationURLObject = buildEvaluationURLObject();

const AppNavigation: FunctionComponent<AppNavigationProps> = ({
  whatsApp,
  phone,
  items = [],
  hideActions,
  variant = AppNavigationVariant.PRIMARY,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(BreakpointQueryName.MD));

  const onMobileItemClick = useCallback(
    (onClick) => () => {
      setMobileMenuOpen(false);

      if (onClick) {
        onClick();
      }
    }, [],
  );

  const goToEvaluationPage = useCallback(
    () => goToPage(evaluationURLObject), [],
  );

  const toggleMobileMenu = useCallback(
    () => {
      setMobileMenuOpen(!mobileMenuOpen);
    }, [mobileMenuOpen],
  );

  const closeMobileMenu = useCallback(
    () => setMobileMenuOpen(false), [],
  );

  return (
    <>
      <header
        css={styles.header}
        $variant={variant}
      >
        <LayoutWrapper maxWidth={WrapperSize.XL}>
          <MainNavigation
            render={{
              logo: (
                <Link
                  href="/"
                  title="Torna alla pagina principale del sito"
                >
                  <Logo />
                </Link>
              ),
              actions: (
                <div css={styles.actions}>
                  {isMobile && (
                    <ChatOnWhatsApp number={whatsApp}>
                      <Symbol path={ICON_WHATSAPP} />
                    </ChatOnWhatsApp>
                  )}
                  <a
                    css={styles.phone}
                    href={`tel:${phone}`}
                    title="Chiamaci per avere maggiori informazioni"
                  >
                    <Symbol
                      path={ICON_PHONE}
                      size={20}
                    />
                    <span>{phone}</span>
                  </a>
                  {!hideActions && (
                    <div
                      css={styles.cta}
                      data-ref="app-navigation-actions"
                    >
                      <Button
                        label="Valuta gratis"
                        size={ButtonSize.SMALL}
                        href={evaluationURLObject.as}
                        onClick={goToEvaluationPage}
                        dataTracking="cta-navigation-evaluation"
                      />
                    </div>
                  )}
                </div>
              ),
            }}
            items={items.filter((item) => !item.mobileOnly)}
            mobileMenuOpen={mobileMenuOpen}
            navigationControlHandler={
              items.length
                ? toggleMobileMenu
                : null
            }
          />
        </LayoutWrapper>
      </header>
      {items.length > 0 && (
        <aside
          css={styles.mobileMenuWrapper}
          $mobileMenuOpen={mobileMenuOpen}
          aria-hidden={mobileMenuOpen}
        >
          <SideNavigation activeItemId={2}>
            {items.map((item) => (
              <SideNavigation.Item
                key={item.id}
                id={item.id}
                label={item.label}
                urlObject={item.urlObject}
                onClick={onMobileItemClick(item.onClick)}
              >
                {item.subItems && item.subItems.length > 0 && (
                  item.subItems.map((subItem) => (
                    <SideNavigation.SubItem
                      key={subItem.id}
                      id={subItem.id}
                      highlighted={subItem.highlighted}
                      label={subItem.label}
                      urlObject={subItem.urlObject}
                      onClick={onMobileItemClick(subItem.onClick)}
                    />
                  )))}
              </SideNavigation.Item>
            ))}
          </SideNavigation>
        </aside>
      )}
      {items.length > 0 && (
        <button
          css={styles.mobileMenuOverlay}
          $mobileMenuOpen={mobileMenuOpen}
          onClick={closeMobileMenu}
          aria-label="Chiudi il menù di navigazione"
        />
      )}
    </>
  );
};

export default AppNavigation;
