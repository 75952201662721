import { Article as SchemaArticle } from 'schema-dts';
import { ArticleType } from '../../domain/articleType';
import {
  Article, ArticleBlock, Guide, NodeType,
} from '../../domain/graphcms';
import { stripMarkdown } from '../../client/utils/string/string';
import { is } from '../../client/utils/graphcms/nodeUtils';

export function getBlogArticleStructuredData(article: Article, articleUrl: string, articleType: ArticleType): SchemaArticle {
  return {
    '@type': articleType === ArticleType.NEWS ? 'NewsArticle' : 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': articleUrl,
    },
    headline: article.title,
    articleSection: articleType === ArticleType.GUIDE ? (article as Guide).categories.join(',') : undefined,
    keywords: article.seo?.keywords?.join(','),
    articleBody: article.mainColumn
      .filter((block) => is(block, NodeType.ARTICLE_BLOCK))
      .map(({ content }: ArticleBlock) => stripMarkdown(content)).join('\n'),
    image: article.metaPreview?.image?.url ? [article.metaPreview.image.url] : [],
    datePublished: article.published,
    dateModified: article.publishedAt,
    author: {
      '@type': 'Person',
      name: 'Redazione',
      url: 'https://www.dove.it/autore/redazione',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Dove.it',
      logo: {
        '@type': 'ImageObject',
        url: 'https://cdn.dove.it/static/assets/logo.png',
      },
    },
    description: article.abstract,
  };
}
