import {
  buildURLObject, withQueryString, slugIt, QueryObject,
} from './common';
import {
  AGENCIES_URL, AGENCIES_AS, AGENCY_URL, REAL_ESTATE_AGENCY_URL, REAL_ESTATE_AGENCY_AS,
} from './paths';

export interface BuildAgencyByLocalityURLObject {
  locality: string,
  [key: string]: string,
}

export function buildAgenciesURLObject(query: QueryObject = {}) {
  return buildURLObject(AGENCIES_URL, withQueryString(AGENCIES_AS, query), query);
}

export function buildAgencyByLocalityURLObject(
  { locality, ...rest }: BuildAgencyByLocalityURLObject,
) {
  return buildURLObject(
    AGENCY_URL,
    withQueryString(`${AGENCIES_AS}/${slugIt(locality)}`, rest),
    { locality: slugIt(locality), ...rest },
  );
}

export function buildRealEstateAgencyURLObject({ locality, slug, ...rest }: QueryObject) {
  return buildURLObject(
    REAL_ESTATE_AGENCY_URL,
    withQueryString(`${REAL_ESTATE_AGENCY_AS}/${slugIt(locality)}/${slug}`, rest),
    { locality: slugIt(locality), slug, ...rest },
  );
}
