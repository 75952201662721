import { Link, Logo } from '@doveit/lego';
import React from 'react';
import LayoutWrapper from '../../layouts/layout-wrapper/LayoutWrapper';
import { URLObject } from '../../utils/links';
import SocialIcon, { SocialIconConfig } from '../social-icon/SocialIcon';
import * as styles from './Footer.style';
import ChatOnWhatsApp from '../../containers/chat-on-whatsapp/ChatOnWhatsApp';

export interface FooterLink {
  label: string,
  urlObject: URLObject,
  title?: string,
  onClick?: VoidFunction,
}

export interface FooterLinkGroup {
  groupName: string,
  items: FooterLink[],
}

export interface FooterProps {
  whatsApp: string,
  tagline: string,
  info: {
    company: string,
    address: string,
    phoneNumber: string,
    email: string,
    VAT: string,
    REA: string,
  },
  links?: FooterLinkGroup[],
}

const Footer: React.FC<FooterProps> = ({
  whatsApp,
  tagline,
  info,
  links = [],
}) => (
  <footer css={styles.base}>
    <LayoutWrapper>
      <div css={styles.inner}>
        <div css={links.length > 0 && styles.infoCell}>
          <div css={styles.logo}>
            <Logo />
          </div>
          <strong
            css={styles.tagline}
            data-ref="tagline"
            dangerouslySetInnerHTML={{ __html: tagline }}
          />

          <div
            css={styles.info}
            data-ref="info"
          >
            <p>
              <strong data-ref="company">
                {info.company}
              </strong>

              {' - '}

              <span data-ref="address">
                {info.address}
              </span>
            </p>

            <p>
              <a
                href={`tel:${info.phoneNumber}`}
                title="Chiamaci"
                data-ref="phoneNumber"
              >
                Tel: {info.phoneNumber}
              </a>

              {' - '}

              <a
                href={`mailto: ${info.email}?subject=Richiesta informazioni`}
                title="Contattaci"
                data-ref="email"
              >
                E-mail: {info.email}
              </a>
            </p>

            <p>
              <span data-ref="VAT">
                P.IVA {info.VAT}
              </span>

              {' - '}

              <span data-ref="REA">
                REA {info.REA}
              </span>
            </p>
          </div>

          <div css={styles.socialIcons}>
            <SocialIcon
              type={SocialIconConfig.FACEBOOK}
            />
            <SocialIcon
              type={SocialIconConfig.LINKEDIN}
            />
            <SocialIcon
              type={SocialIconConfig.INSTAGRAM}
            />
            <SocialIcon
              type={SocialIconConfig.TWITTER}
            />
            <SocialIcon
              type={SocialIconConfig.YOUTUBE}
            />
          </div>

          <ChatOnWhatsApp number={whatsApp} />
        </div>
        {links.length > 0 && (
          <nav div css={styles.nav}>
            {links.map((group) => (
              <div key={group.groupName} css={styles.linksCell}>
                <div>
                  <div css={styles.linksGroupTitle}>{group.groupName}</div>
                  <ul>
                    {group.items.map((link) => (
                      <li
                        data-ref="footer-link"
                        key={link.label}
                        css={styles.link}
                      >
                        <Link
                          href={link.urlObject.as}
                          title={link.title}
                          onClick={link.onClick}
                        >
                          {link.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </nav>
        )}
      </div>
    </LayoutWrapper>
  </footer>
);

export default Footer;
