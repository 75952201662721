import { createGlobalStyle, ExecutionContext } from 'styled-components';
import reset from 'styled-reset';
import { getTypography, TypographyName } from '@doveit/lego';

export default createGlobalStyle`
  ${reset}

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  html {
    font-size: ${(p: ExecutionContext) => p.theme.remBase}px;
  }

  html,
  body {
    ${(p: ExecutionContext) => getTypography(p.theme, TypographyName.BODY)}
    overflow-x: hidden;
  }

  a {
    color: currentColor;
  }

  strong {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  ol[type=A] {
    list-style-type: upper-latin;
  }

  ol[type=i] {
    list-style-type: lower-roman;
  }
`;
