import { css } from 'styled-components';
import {
  BreakpointQueryName, getSpacing, SpacingName, getTypography, TypographyName, ThemedFunctor, rgba, FontWeight,
} from '@doveit/lego';
import variables from '../../themes/variables';

export const wrapper: ThemedFunctor = ({ theme }) => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: ${variables.navigationHeight.mobile};

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    height: ${variables.navigationHeight.desktop};
  }
`;

export const sideWrapper = css`
  align-items: center;
  display: flex;
`;

export const control: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginRight(theme, SpacingName.SMALL)};
  appearance: none;
  background-color: transparent;
  color: currentColor;
  border: none;
  padding: 0;
  line-height: 0;

  &:hover {
    cursor: pointer;
  }

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    display: none;
  }
`;

export const logo: ThemedFunctor = ({ theme }) => css`
  width: 6.85rem;

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    width: 9rem;
  }

  a {
    display: block;
    line-height: 1;
  }
`;

export const items: ThemedFunctor = ({ theme }) => css`
  flex-grow: 1;
  flex-wrap: wrap;
  display: none;
  height: 100%;

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    display: flex;
    padding: 0 ${theme.spaceUnits.desktop.SMALLER} 0 ${theme.spaceUnits.desktop.SMALL};
  }

  ${theme.breakpoints.up(BreakpointQueryName.XL)} {
    padding: 0 ${theme.spaceUnits.desktop.SMALLER} 0 ${theme.spaceUnits.desktop.MEDIUM};
  }
`;

export const item: ThemedFunctor = ({
  $active,
}) => css`
  flex-shrink: 0;
  position: relative;

  ${$active && `
    > a {
      text-decoration: underline;
    }
  `}
`;

export const link: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  box-sizing: border-box;
  outline: 0;
  background: none;
  border: 0;
  padding: 0 ${theme.spaceUnits.desktop.SMALLER};
  text-decoration: none;
  text-transform: uppercase;
  height: 100%;
  align-items: center;
  display: flex;
  font-weight: ${FontWeight.BOLD};

  &:hover {
    text-decoration: underline;
  }
`;

export const subMenu: ThemedFunctor = ({
  theme,
  $open,
}) => css`
  position: absolute;
  left: 0;
  filter: drop-shadow(0px 4px 8px ${rgba(theme.palettes.neutrals.darkest, 0.3)});
  display: none;
  margin-top: -.5rem;
  min-width: 115%;
  z-index: ${theme.zIndex.navbar + 1};

  ${$open && `
    display: block;
  `}
`;

export const subMenuList: ThemedFunctor = ({
  theme,
  $subItemsCount,
}) => css`
  overflow: hidden;
  background: ${theme.palettes.neutrals.lightest};
  width: 16rem;

  ${$subItemsCount > 6 && `
    column-count: 2;
    width: 20rem;
    column-rule: 1px solid ${theme.palettes.neutrals.light};
    column-gap: 0;

    > * {
      display: inline-block;
      width: 100%;
    }
  `}
`;

export const subLink: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  line-height: 1;
  color: ${theme.palettes.neutrals.darkest};
  padding: ${theme.spaceUnits.desktop.SMALLER} ${theme.spaceUnits.desktop.SMALL};
  display: block;
  text-decoration: none;

  &:hover {
    background: ${theme.palettes.neutrals.lighter};
  }
`;
