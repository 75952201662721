import { SingleFamilyResidence } from 'schema-dts';
import { relativeToAbsoluteURL } from '../../client/utils/links';
import { Property } from '../../services/dtos/property';

export function getPropertyStructuredData(property: Property): SingleFamilyResidence {
  const data: SingleFamilyResidence = {
    '@type': 'SingleFamilyResidence',
    url: relativeToAbsoluteURL(property.relativePath),
    name: property.title,
    description: property.abstract,
    numberOfRooms: property.roomsNumber,
    address: {
      '@type': 'PostalAddress',
      streetAddress: property.route || undefined,
      addressLocality: property.locality,
      postalCode: property.postalCode || undefined,
    },
    photo: property.images.map((image) => ({
      '@type': 'ImageObject',
      contentUrl: image.original,
    })),
  };

  if (property.latitude) {
    data.geo = {
      '@type': 'GeoCoordinates',
      latitude: property.latitude,
      longitude: property.longitude,
    };
  }

  return data;
}
