// Homepage
export const HOMEPAGE_URL = '/';
export const HOMEPAGE_AS = '/';

// Properties
export const SERP_URL = '/serp';
export const SERP_AS = '/vendita-case';

export const SOLD_PROPERTIES_URL = '/sold-properties';
export const SOLD_PROPERTIES_AS = '/case-vendute';

export const LISTING_URL = '/listing';

export const SELL_PROPERTY_URL = '/sell-property';
export const SELL_PROPERTY_AS = '/vendere-casa';

export const BUY_PROPERTY_URL = '/buy-property';
export const BUY_PROPERTY_AS = '/comprare-casa';

export const BUILDING_SITE_URL = '/building-site';
export const BUILDING_SITES_URL = '/building-sites';
export const BUILDING_SITE_AS = '/nuove-costruzioni';

// Guides
export const GUIDES_URL = '/guides-serp';
export const GUIDES_AS = '/guida';

export const GUIDE_URL = '/guide';
export const GUIDE_AS = '/guida';

// Education
export const EDUCATION_SERP_URL = '/education-serp';
export const EDUCATION_SERP_AS = '/formazione-agente-immobiliare';
export const EDUCATION_URL = '/education';
export const EDUCATION_AS = '/formazione-agente-immobiliare';

// Education Quiz
export const EDUCATION_QUIZ_INTRO_URL = '/education-quiz-intro';
export const EDUCATION_QUIZ_INTRO_AS = '/formazione-agente-immobiliare/quiz-simulatore-esame';
export const EDUCATION_QUIZ_TEST_URL = '/education-quiz';
export const EDUCATION_QUIZ_TEST_AS = '/formazione-agente-immobiliare/quiz-simulatore-esame/test';
export const EDUCATION_QUIZ_RESULT_URL = '/education-quiz-results';
export const EDUCATION_QUIZ_RESULT_AS = '/formazione-agente-immobiliare/quiz-simulatore-esame/risultati';

// News
export const NEWS_SERP_URL = '/news-serp';
export const NEWS_SERP_AS = '/news';

// Author
export const AUTHOR_URL = '/author';
export const AUTHOR_AS = '/autore';

export const NEWS_URL = '/news';
export const NEWS_AS = '/news';

// Evaluation
export const EVALUATION_URL = '/evaluation';
export const EVALUATION_AS = '/valutazione-immobile';
export const EVALUATION_BY_LOCALITY_URL = '/evaluation-by-locality';

export const EVALUATION_LEAD_URL = '/evaluation-lead';
export const EVALUATION_LEAD_AS = '/valutazione-immobile/lead';

export const EVALUATION_THANK_YOU_URL = '/evaluation-thank-you';
export const EVALUATION_THANK_YOU_AS = '/valutazione-immobile/thank-you';

// Agencies
export const AGENCY_URL = '/agency';
export const AGENCIES_URL = '/agencies';
export const AGENCIES_AS = '/agenzie-immobiliari';

// Real estate agencies
export const REAL_ESTATE_AGENCY_URL = '/real-estate-agency';
export const REAL_ESTATE_AGENCY_AS = AGENCIES_AS;

// Services
export const OUR_SERVICES_URL = '/our-services';
export const OUR_SERVICES_AS = '/i-nostri-servizi';

// Buyer services
export const BUYER_CONSULTANCY_SERVICE_URL = '/buyer-consultancy';
export const BUYER_CONSULTANCY_SERVICE_AS = '/comprare-casa/consulente-acquisto';

// Job offers
export const JOB_OFFER_URL = '/job-offer';
export const JOB_OFFER_AS = '/lavora-con-noi';

// Work with us
export const WORK_WITH_US_URL = '/work-with-us';
export const WORK_WITH_US_AS = '/lavora-con-noi';

// Franchising
export const FRANCHISING_URL = '/franchising';
export const FRANCHISING_AS = '/franchising-immobiliare';

// Become agent
export const BECOME_AGENT_URL = '/become-agent';
export const BECOME_AGENT_AS = '/lavora-con-noi/diventa-agente-immobiliare';

// Join our agents
export const JOIN_OUR_AGENTS_URL = '/join-our-agents';
export const JOIN_OUR_AGENTS_AS = '/unisciti-agli-agenti-doveit';

// Become agent info
export const BECOME_AGENT_INFO_URL = '/become-agent-info';
export const BECOME_AGENT_INFO_AS = '/lavora-con-noi/diventa-agente-immobiliare/info';

// Join our agents info
export const JOIN_OUR_AGENTS_INFO_AS = '/unisciti-agli-agenti-doveit/info';

// Work with as info
export const WORK_WITH_US_INFO_AS = '/lavora-con-noi/info';

// Landing
export const LANDING_CITY_SELLER_AS = '/l/vendita-casa';

export const LANDING_JOIN_OUR_AGENTS_AS = '/l/unisciti-agli-agenti-doveit';

export const LANDING_FRANCHISING_URL = FRANCHISING_URL;
export const LANDING_FRANCHISING_AS = '/l/franchising-immobiliare';

// Thank you
export const THANK_YOU_URL = '/thank-you';
export const THANK_YOU_AS = '/l/thank-you';
export const BUYER_CONSULTANCY_THANK_YOU_AS = '/comprare-casa/consulente-acquisto/thank-you';
export const AUCTION_CONSULTANCY_THANK_YOU_AS = '/comprare-casa/consulenza-aste-immobiliari/thank-you';
export const JOB_APPLICATION_BECOME_AGENT_THANK_YOU_AS = '/lavora-con-noi/diventa-agente-immobiliare/thank-you';
export const JOB_APPLICATION_THANK_YOU_AS = '/lavora-con-noi/thank-you';
export const NOTARY_INTEREST_THANK_YOU_AS = '/preventivo-notaio';

// Agent
export const AGENT_URL = '/agent';
export const AGENT_AS = '/agenti-immobiliari';
