import { createGlobalStyle, ExecutionContext } from 'styled-components';

export default createGlobalStyle`
  #react-autowhatever-address li:nth-child(odd) > div,
  #react-autowhatever-city li:nth-child(odd) > div {
    background-color: ${(p: ExecutionContext) => p.theme.palettes.neutrals.lighter} !important;
  }
  #react-autowhatever-address li:hover > div,
  #react-autowhatever-city li:hover > div {
    background-color: ${(p: ExecutionContext) => p.theme.palettes.neutrals.light} !important;
  }
`;
