import {
  buildURLObject, withQueryString, slugIt, QueryObject, URLObject,
} from './common';
import {
  EVALUATION_URL, EVALUATION_AS, EVALUATION_BY_LOCALITY_URL,
} from './paths';

export interface BuildEvaluationByLocalityURLObject {
  locality: string,
  [key: string]: any,
}

export function buildEvaluationURLObject(query: QueryObject = {}): URLObject {
  return buildURLObject(
    EVALUATION_URL,
    withQueryString(EVALUATION_AS, query),
    query,
  );
}

export function buildEvaluationByLocalityURLObject(
  { locality, ...rest }: BuildEvaluationByLocalityURLObject,
): URLObject {
  return buildURLObject(
    EVALUATION_BY_LOCALITY_URL,
    withQueryString(`${EVALUATION_AS}/${slugIt(locality)}`, rest),
    { locality: slugIt(locality), ...rest },
  );
}
