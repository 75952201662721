import React, { FunctionComponent, ReactNode } from 'react';
import { ThemedFunctor } from '@doveit/lego';
import * as styles from './LayoutWrapper.style';
import { WrapperSize } from '../../types';

export interface LayoutWrapperProps extends React.AriaAttributes {
  maxWidth?: WrapperSize,
  withoutPad?: boolean,
  addCss?: ThemedFunctor,
  children: ReactNode,
}

const LayoutWrapper: FunctionComponent<LayoutWrapperProps> = ({
  maxWidth,
  withoutPad,
  addCss,
  children,
  ...others
}) => (
  <div
    css={styles.base}
    $addCss={addCss}
    {...others}
  >
    <div
      css={styles.content}
      $maxWidth={maxWidth || WrapperSize.L}
      $withoutPad={withoutPad}
      data-ref="layout-wrapper-container"
    >
      {children}
    </div>
  </div>
);

export default LayoutWrapper;
